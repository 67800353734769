var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.stickyFooterV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $template = $('.js-sticky-footer-v1', context);
      var $footer = $template.closest('.site-footer');
      var isLoyaltyMember = parseInt(site.userInfoCookie.getValue('is_loyalty_member')) || 0;
      var $smashcashItem = $('.js-sticky-footer-item-smashcash', context);
      var $itemTrigger = $('.js-sticky-footer-item-trigger', $template);
      var $itemWrapper = $('.sticky-footer__item', $template);
      var $itemCloseButton = $('.js-sticky-footer-item-close', $template);
      var $smashCashTrigger = $('.js-sticky-footer-item-smashcash-trigger', $template);
      var offset = 0;
      var $html = $('html');
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();
      var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);

      if (!$footer.length) {
        return;
      }

      if (isLoyaltyMember !== 0) {
        $smashcashItem.addClass('disabled');
      }

      $itemTrigger
        .off('click')
        .on('click', function (e) {
          e.preventDefault();
          displayItemPopUp($(this));
        })
        .on('mouseenter', function () {
          if (isDesktop) {
            displayItemPopUp($(this));
          }
        });

      $itemWrapper.on(
        'mouseleave',
        _.debounce(function () {
          $(this).removeClass('active');
        }, 200)
      );

      $itemCloseButton.once().on('click', function () {
        $(this).closest('.sticky-footer__item').removeClass('active');
      });

      $smashCashTrigger.once().on('click', function (e) {
        // Display pop-up for smashcash item. This logic is ported from sticky_smashcash_v1.
        e.preventDefault();
        generic.overlay.launch({
          html: site.template.get({
            name: 'smashcash_popup'
          }),
          className: 'smashcash-overlay',
          cssStyle: {
            width: '650px'
          }
        });
      });

      function setStickyFooter() {
        offset = $footer.offset().top - $(window).height() + $template.innerHeight();
        $html.toggleClass('sticky-footer-active', $(window).scrollTop() < offset);
      }

      function displayItemPopUp($item) {
        $itemWrapper.removeClass('active');
        $item.closest('.sticky-footer__item').addClass('active');
      }

      $(window).on(
        'scroll resize',
        _.throttle(function () {
          setStickyFooter();
        }, 200)
      );

      Unison.on('change', function () {
        bp = Unison.fetch.now();
        isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
      });
    }
  };
})(jQuery);
